.launchcontainer {
  overflow: hidden;
  position: relative;
  .launch_top {
    position: relative;
    z-index: 10;
    width: 1226px;
    margin: 250px auto 0;
  }
  .launch_Wrap {
    overflow: visible;
    position: relative;
    margin-top: 150px;
    text-align: center;
    .launch_vd {
      position: relative;
      z-index: 10;
      width: 326px;
      height: 707px;
      border-radius: 30px;
      border: 10px solid #000000;
    }
  }
  .thunmb {
    position: relative;
    width: 1226px;
    margin: -145px auto 0;
    padding-bottom: 512px;
    text-align: center;
  }
}
