.Gimagecontainer {
  position: relative;
  width: 1200px;
  margin: 0 auto;
  .device_top {
    width: 1200px;
    margin: 18px 0 505px;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.02),
      60px 60px 50px rgba(0, 0, 0, 0.05);
    > img {
      width: 100%;
      height: auto;
      vertical-align: top;
      background-color: white;
    }
    > video {
      width: 100%;
    }
  }
  .premium_style {
    position: relative;
    .pre_tit {
      font-size: 12px;
      font-weight: 900;
    }
    .pre_txt {
      margin-top: 35px;
      font-size: 10px;
      color: #939393;
    }
    .con_top {
      position: relative;
      padding-top: 654px;
      width: 1200px;
      margin: 0 auto;
    }
  }
}
