.react-p5{
    height: 100%;
    color: rgb(255, 255, 255);
    width: 100%;
    display: flex;
    justify-content: center;
    
    #defaultCanvas0{
        // width: calc(100% - (100vh / 3)) !important;
        height: 100vh !important;
    }
}

.react-p5{
	animation: fadein 5s;
	-moz-animation: fadein 5s; /* Firefox */
	-webkit-animation: fadein 5s; /* Safari and Chrome */
	-o-animation: fadein 5s; /* Opera */
	animation-fill-mode: forwards;

}


// Fade In
@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}