.creditcontainer {
  .credit_top {
    overflow: hidden;
    width: 1226px;
    margin: 262px auto 0;
    text-align: center;
    > img {
      width: 1201px;
      height: 827px;
    }
  }
}
