.archivecontainer {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .cnt_top {
    position: relative;
    height: 1895px;
    padding-top: 253px;
    background: url(../../../../../_asset/images/29TV/bg_archive_2.jpg);
    background-size: cover;
    box-sizing: border-box;
    .archive_top {
      position: relative;
      width: 1226px;
      height: 100%;
      margin: 0 auto;
      text-align: right;
    }
  }
  .cnt_bottom {
    position: relative;
    width: 100%;
    height: 820px;
    background: #fe4b04;
    .desc_bx {
      position: relative;
      z-index: 10;
      width: 1226px;
      margin: 0 auto;
    }
  }
  .cnt_bottomTwo {
    position: relative;
    width: 100%;
    height: 1000px;
    .desc_bx {
      position: relative;
      z-index: 10;
      width: 1226px;
      margin: 0 auto;
      .archive_Wrap {
        float: right;
        margin-right: 45px;
        > img {
          width: 345px;
          height: 720px;
          border-radius: 37px;
          box-shadow: 33px 30px 79px rgba(0, 0, 0, 0.4);
        }
      }
    }
    .middle_title {
      font-size: 50px;
      letter-spacing: 10px;
      line-height: 1.7;
      font-weight: 600;
    }
    .middle_sutext {
      font-size: 16px;
      padding-top: 50px;
      line-height: 2;
    }
  }
  .cnt_bottomThree {
    position: relative;
    width: 100%;
    height: 680px;
    .desc_bx {
      position: relative;
      z-index: 10;
      width: 1226px;
      margin: 0 auto;
      .ImgageWrap {
        > img {
          width: 345px;
          height: 720px;
        }
      }

      .bottom_title {
        font-weight: 600;
        font-size: 50px;
        line-height: 1.7;
        letter-spacing: 10px;
      }
      .bottom_subtext {
        margin-top: 50px;
        font-size: 16px;
        line-height: 2;
      }
    }
  }
}
