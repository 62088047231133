.interveiwcontainer {
  overflow: hidden;
  position: relative;
  z-index: 10;
  width: 1226px;
  height: auto;
  margin: 0 auto 0;
  .interview_block {
    overflow: hidden;
    margin-top: 265px;
    text-align: center;
    .contents_01 {
      font-size: 30px;
      color: #fff;
    }
    .contents_02 {
      position: relative;
      margin: 45px 0 605px;
      > img {
        width: 1222px;
        height: 375px;
      }
    }
    .contents_Wrap {
      display: flex;
      justify-content: space-between;
      .contents_03 {
        flex: 1;
        max-width: 415px;
        width: 100%;
        height: 415px;
        border-radius: 50%;
        background: #fff;
        line-height: 415px;
        color: #161616;
        .contents_title {
          font-size: 30px;
          font-weight: 900;
        }
      }
      .contents_04 {
        flex: 1;
        text-align: left;
        max-width: 573px;
        width: 100%;
        margin-top: 117px;
        color: #fff;
        font-size: 16px;
        line-height: 2;
      }
    }
  }
}
