.Pvalue_container {
  overflow: hidden;
  height: 800px;
  background: #fafbfb;
  .Pvalue_cont {
    overflow: hidden;
    position: relative;
    width: 960px;
    height: 100%;
    margin: 0 auto;
    .Pvalue_text_box {
      overflow: hidden;
      position: relative;
      margin-top: 100px;
      z-index: 10;
      .Pvalue_tit {
        font-size: 13px;
        font-weight: 500;
      }
      .Pvalue_text {
        padding-top: 20px;
        font-size: 13px;
        line-height: 1.7;
        font-weight: 500;
      }
    }
    .Pvalue_bg {
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 100px;
      width: 960px;
      height: 612px;
      > img {
        width: 100%;
      }
    }
  }
}
