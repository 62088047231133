
.ButtonContainer{
  display: flex;
  align-items: flex-end;
  .Black{
    color: #000;
    .menuIcon{
      fill: #000;
    }
    .menubtn{
      color: #000;
    }
  }
  .White{
    color:#fff;
    .menuIcon{
      fill: #fff;
    }
    .menubtn{
      color: #fff;
    }
  }
  .menubtn {
    margin-right: 4px;
    font-family: Montserrat;
  }
  .btn a{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  .btn{
    padding-right: initial;
    font-size: 15px;
    font-weight: 500;

    &.disable {
      pointer-events: none;
      opacity: 0.3;
    }
  }
  .Black:hover{
    
    .menuIcon{
      fill: #fff;
    }
    .menubtn{
      color:#fff;
    }
  }
  .White:hover{
    
    .menuIcon{
      fill: #e7304d;
    }
    .menubtn{
      color:#e7304d;
    }
  }
  
}
@media (max-width:1270px) {
  .ButtonContainer{
    .btn{
      font-size: 13px;
    }
  }
}

//Tablet | Mobile 분기점
@media all and (max-width:1024px) {
  .ButtonContainer{
    .menubtn{
      line-height: 1;
    }
    .btn{
      align-items: flex-start;
      font-size: 16px;
    }
    .btn.about{
      display: none;
    }
  }
}

@media all and (max-width:400px) {
  .ButtonContainer{
    .btn{
      font-size: 14px;
    }
  }
}

@media all and (max-width:300px) {
  .ButtonContainer{
    .btn{
      font-size: 12px;
    }
  }
}