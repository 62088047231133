.Gfooter_container {
  position: relative;
  width: 100%;
  .story_inner {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    padding: 177px 0 158px;
  }
  .member {
    background: #333;
    padding: 187px 0 168px;
    .inner {
      position: relative;
      width: 1200px;
      margin: 0 auto;
    }
  }
}
