
// 백그라운드 영상 overflow 에 따른 Hidden 처리
.fp-section.fp-table, .fp-slide.fp-table{
	overflow: hidden;
}
.SecondPage {
	// background-color: #ffffff !important;
	// background-image: url('../../../_asset/images/mainback/bg-02.png');
	// background-repeat: no-repeat;
	// -webkit-background-size: cover;
	// -moz-background-size: cover;
	// -o-background-size: cover;
	// background-size: cover;
	.video-wrap{
		z-index: 1;
		//  top:0;
		//  left: 0;
		//  width: 100%;
		//  height: auto;
		display: flex;
		justify-items: center;
	 }
	 .bg-background-video{
		 height: 100vh;
		 position: relative;
	 }	
	 .animation-video{
		position: absolute;
		width: 100%;
		margin: auto;
		position: absolute;
		top: 0; left: 0; bottom: 0; right: 0;
	 }	 
	.Link {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		padding-bottom: 155px;
		position: absolute;
		width: 100%;
		z-index: 9999;
		top: 0;
		left: 0;
	}
}

//Tablet | Mobile 분기점
@media all and (max-width:1024px) {
	.SecondPage{
	  .Link{
		padding-bottom: 130px;
	  }
	}
}
  
@media all and (max-width:767px) {
	.SecondPage{
		.Link{
		padding-bottom: 120px;
		}
	}
}