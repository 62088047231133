.header{
  display: flex;
  position: absolute;
  left: 50%;
  transform:translateX(-50%);
  padding: 80px 30px 0px;
  max-width: 1630px;
  width: 100%;
  transition: opacity 0.3s ease 0s;
  justify-content: space-between;
  align-items: flex-end;
  user-select: none;
  z-index: 1;
}
//Tablet | Mobile 분기점
@media all and (max-width:1024px) {
  .header{
    padding: 70px 50px 0;
    align-items: flex-start;
  }
}

@media all and (max-width:767px) {
  .header{
    padding: 30px 25px 0;
  }
}