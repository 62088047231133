.AboutContainer{
    .AboutMain{
        padding: 100px 30px;
        color: #ffffff;
        max-width: 1630px;
        margin: auto;
    }
    .AboutList{
        width: 100%;
        display: flex;
    }
    .AboutList.People, .AboutList.Collab{
        margin-top: 190px;
    }
    .AboutList.Brand{
        margin-top: 130px;
    }
    .AboutRightBox{
        display: flex;
        flex-direction: column;
        font-family: NotoSansKr;
        flex: 4;
    }
    .AboutLeftBox{
        flex: 1;
    }
    .AboutRightSubBox{
        display: flex;
    }
    .AboutRightTitle{
        font-size: 40px;
        font-weight: 500;
        line-height: 1.38;
    }
    .AboutRightSub{
        flex: 1;
        font-size: 18px;
        font-weight: 300;
        line-height: 1.78;
        margin-top: 60px;
    }
    .AboutRightSub.a{
        margin-right: 30px;
    }
    .AboutLeftTitle{
        font-family: Montserrat;
        font-size: 25px;
        font-weight: 800;
        line-height: 1.38;
    }
    .AboutBrandImgBox{
        display: flex;
        flex-wrap: wrap;
        max-width: 1210px;
        img{
            width: 190px;
            height: auto;
            margin-right: 35px;
        }
    }
}
@media all and (max-width:1550px) {
    .AboutContainer{
        .AboutLeftTitle{
            font-size: 24px;
        }
        .AboutRightTitle{
            font-size: 35px;
        }
        .AboutRightSub{
            font-size: 17px;
        }
        .AboutBrandImgBox{
            img{
                width: 170px;
                height: auto;
            }
        }
    }
}
  @media all and (max-width:1440px) {
    .AboutContainer{
        .AboutLeftTitle{
            font-size: 22px;
        }
        .AboutRightTitle{
            font-size: 30px;
        }
        .AboutRightSub{
            font-size: 15px;
        }
        .AboutBrandImgBox{
            img{
                width: 155px;
                margin-right: 30px;
            }
        }
    }
  }
  @media all and (max-width:1220px) {
    .AboutContainer{
        .AboutLeftTitle{
            font-size: 18px;
        }
        .AboutRightTitle{
            font-size: 24px;
        }
        .AboutRightSub{
            font-size: 13px;
        }
        .AboutList.People, .AboutList.Collab{
            margin-top: 110px;
        }
        .AboutRightSub{
            margin-top: 30px;
        }
        .AboutRightSub.a{
            margin-right:25px;
        }
        .AboutBrandImgBox{
            img{
                width: 130px;
                height: auto;
                margin-right: 20px;
            }
        }
        .AboutList.Brand{
            margin-top: 100px;
        }
    }
  }
  //Tablet | Mobile 분기점
  @media all and (max-width:1024px) {
    .AboutContainer{
        .AboutMain{
            padding: 80px 50px;
        }
        .AboutRightSub.a{
            margin-right:20px;
        }
        .AboutRightSub{
            margin-top: 25px;
            font-size: 12px;
        }
        .AboutList.People, .AboutList.Collab{
            margin-top: 80px;
        }
        .AboutLeftTitle{
            font-size: 16px;
        }
        .AboutRightTitle{
            font-size: 20px;
        }
        .AboutBrandImgBox{
            img{
                width: 110px;
                margin-right: 15px;
            }
        }
    }
}
@media all and (max-width:900px) {
    .AboutContainer{
        .AboutMain{
            padding: 70px 50px;
        }
        .AboutList.People, .AboutList.Collab{
            margin-top: 80px;
        }
        .AboutLeftTitle{
            font-size: 13px;
        }
        .AboutRightTitle{
            font-size: 17px;
        }
        .AboutRightSub{
            font-size: 12px;
        }
        .AboutBrandImgBox{
            img{
                width: 91.7px;
                margin-right: 15px;
            }
        }
    }
}
@media all and (max-width:767px) {
    .AboutContainer{
        .AboutMain{
            padding: 50px 30px 0;
        }
        .AboutList.People, .AboutList.Collab{
            margin-top: 50px;
        }
        .AboutList{
            flex-direction: column;
        }
        .AboutRightSubBox{
            flex-direction: column;
        }
        .AboutLeftTitle{
            font-size: 18px;
            margin-bottom: 25px;
            font-weight: 800;
        }
        .AboutRightTitle{
            font-size: 23px;
            br{
                display: none;
            }
        }
        .AboutRightSub{
            font-size: 14px;
        }
        .AboutList.Brand{
            margin-top: 50px;
        }
        .AboutBrandImgBox{
            img{
                width: 32%;
                margin-right: 1%;
            }
        }
        .AboutRightSub.a{
            margin-right: initial;
        }
    }
}
@media all and (max-width:360px) {
    .AboutContainer{
      .AboutRightTitle{
        font-size: 20px;
      }
      .AboutLeftTitle{
          font-size: 16px;
          margin-bottom: 20px;
      }
      .AboutRightSub{
          margin-top: 15px;
          font-size: 13px;
      }
    }
  }