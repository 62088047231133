@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}






.FirstPage{

  .main-slider__header {
      max-width: 1630px;
      padding: 0 30px;
      width: 100%;
      left: 50%;
      transform:translateX(-50%);
      font-weight: 700;
      display: block;
      font-size: 24px;
      position: absolute;
      margin-top: 160px;
      z-index: 50;
  }
  .header2{
      z-index: 20;
  }
  .FirstPageTitle {
      color :#000;

      font-family: Montserrat;
      font-weight: 300;
      font-size: 50.5px;
      z-index: 20;
      user-select: none;

      // Slide-in-left
      -webkit-animation: fade-in-left 1.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
      animation: fade-in-left 1.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
      a{
        color :#000;
      }
  }
  .FirstPageTitle{

  }
  .CrossGrid{
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: -1000;

    }
    .Link{
      position: relative;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 155px;
  }
}

@media (max-width:1270px) {
  .FirstPage{
    .FirstPageTitle{
      font-size: 40px;
    }
  }
}

//Tablet | Mobile 분기점
@media all and (max-width:1024px) {
  .FirstPage{
    .main-slider__header{
      margin-top: 140px;
      padding: 0 50px;
    }
    .FirstPageTitle{
      font-size: 38px;
    }
    .Link{
      padding-bottom: 130px;
    }
  }
}

@media all and (max-width:767px) {
  .FirstPage{
    height: 100vh !important; 
    .main-slider__header{
      margin-top: 120px;
      padding: 0 30px;
    }
    .FirstPageTitle{
      // display: none;
    }
    .Link{
      padding-bottom: 120px;
    }
  }
}