.ContactContainer{
    .ContactMain{
        font-family: Montserrat;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        max-width: 1630px;
        width: 100%;
        margin: auto;
        padding: 100PX 30px;
    }
    .ContactList{
        display: flex;
        flex: 1;
    }
    .ContactList.Support{
        margin-top: 200px;
    }
    .ContactBottomList{
        display: flex;
        margin-top: 200px;
    }
    .ContactLeftBox{
        // display: flex;
        // align-items: center;
        max-width: 250px;
        width: 100%;
    }
    .ContactRightTitle{
        font-size: 40px;
        font-weight: normal;
        line-height: 1.38;
        cursor: pointer;
        span{
            font-weight: 500;
            font-family: NotoSansKr;
        }
    }
    .ContactLeftTitle{
        font-size: 25px;
        font-weight: 800;
        line-height: 1.38;
    }
    .ContactRightTitle.Sns{
        a{
            color: #fff;
        }
        a:hover{
            border-bottom: 1px solid;
        }

    }
    .ContactRightTitle.Mail{
        font-family: Montserrat;
        line-height: 1;
        color: #fff;
    }
    .ContactRightTitle.Mail:hover{
        border-bottom: 1px solid;
    }
    .ContactRightTitle.Mail{
        padding: initial;
    }
}
@media all and (max-width:1550px) {
    .ContactContainer{
        .ContactLeftTitle{
            font-size: 24px;
        }
        .ContactRightTitle{
            font-size: 35px;
        }
    }
}
@media all and (max-width:1440px) {
    .ContactContainer{
        .ContactLeftTitle{
            font-size: 22px;
        }
        .ContactRightTitle{
            font-size: 30px;
        }
        .ContactList.Support{
            margin-top: 150px;
        }
        .ContactBottomList{
            margin-top: 150px;
        }
    }
  }
  @media all and (max-width:1220px) {
    .ContactContainer{
        .ContactLeftTitle{
            font-size: 22px;
            width: 250px;
        }
        .ContactRightTitle{
            font-size: 30px;
        }
        .ContactBottomList{
            flex-direction: column;
        }
        .ContactList.Support, .ContactList.Follow{
            margin-top: 100px;
        }
        .ContactBottomList{
            margin-top: 100px;
        }
    }
  }

  //Tablet | Mobile 분기점
  @media all and (max-width:1024px) {
    .ContactContainer{
        .ContactMain{
            padding: 80px 50px 30px;
        }
        .ContactLeftTitle{
            font-size: 20px;
            width: 150px;
        }
        .ContactLeftBox{
            max-width: 150px;
        }
        .ContactRightTitle{
            font-size: 26px;
        }
        .ContactBottomList{
            flex-direction: column;
        }
        .ContactList.Support, .ContactList.Follow{
            margin-top: 100px;
        }
        .ContactBottomList{
            margin-top: 100px;
        }
    }
  }

  @media all and (max-width:900px) {
    .ContactContainer{
        .ContactMain{
            padding: 70px 50px 30px;
        }
        .ContactRightTitle{
            font-size: 24px;
        }
    }
  }

  @media all and (max-width:767px) {
    .ContactContainer{
        .ContactMain{
            padding: 50px 25px 20px;
        }
        .ContactList{
            flex-direction: column;
        }
        .ContactLeftTitle{
            font-size: 25px;
            margin-bottom: 30px;
            width: initial;
        }
        .ContactRightTitle{
            font-size: 30px;
        }
        .ContactBottomList{
            flex-direction: column;
        }
        .ContactList.Support, .ContactList.Follow{
            margin-top: 80px;
        }
        .ContactBottomList{
            margin-top: 80px;
        }
    }
  }

@media all and (max-width:400px) {
    .ContactContainer{
        .ContactLeftTitle{
            font-size: 18px;
            margin-bottom: 20px;
        }
        .ContactRightTitle{
            font-size: 23px;
        }
        .ContactList.Support, .ContactList.Follow{
            margin-top: 50px;
        }
        .ContactBottomList{
            margin-top: 50px;
        }
    }
}

@media all and (max-width:330px) {
    .ContactContainer{
        .ContactMain{
            padding: 40px 25px 0px;
        }
        .ContactLeftTitle{
            font-size: 15px;
            margin-bottom: 15px;
        }
        .ContactRightTitle{
            font-size: 20px;
        }
        .ContactList.Support, .ContactList.Follow{
            margin-top: 40px;
        }
        .ContactBottomList{
            margin-top: 40px;
        }
    }
}