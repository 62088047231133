



.PageControllerContainer {

  
  display: flex;
  flex-direction: column;
  right: 0;
  bottom: 80px;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  align-items: flex-end;
  .PageControllerMain{

    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .PageControllerNumber{
    font-family: Montserrat;

    font-size: 15px;
    font-weight: normal;
    padding: 10px 0;
    margin-left:1px;
  }
  .Black{
    color: #000;
    .arrowIcon{
      fill: #000;
    }
  }
  .White{
  color :#fff;
  .arrowIcon{
  fill: #fff;
}
  }
  
  // .arrowIcon:hover{
  //     fill: rgba(255, 81, 13, 0.8);
      
  // }
  
}
@media all and (max-width:1270px) {
  .PageControllerContainer{
    .PageControllerNumber{
      font-size: 14px;
      padding: 5px 0;
    }
  }
}

//Tablet | Mobile 분기점
@media all and (max-width:1024px) {
  .PageControllerContainer{
    .PageControllerNumber{
      font-size: 14px;
      padding: 5px 0;
    }
  }
}

@media all and (max-width:767px) {
  .PageControllerContainer{
    display: none;
  }
}