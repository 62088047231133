.overviewcontainer {
  overflow: hidden;
  position: relative;
  width: 1226px;
  margin: 243px auto 0;
  text-align: center;
  .overview_top {
    position: absolute;
    right: 50%;
    margin-right: 215px;
    display: flex;
    width: 375px;
    justify-content: space-between;
  }
  .mv-wrap {
    display: inline-block;
    width: 820px;
    height: 820px;
    margin: 150px 0 130px;
    text-align: center;
  }
  .strong_text {
    font-size: 55px;
    font-weight: 600;
    line-height: 1.5;
  }
}
