.Poverflow_container {
  overflow: hidden;
  height: 800px;
  background: #fff;
  .over_cont {
    overflow: hidden;
    width: 960px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    .over_tit {
    }
    .over_txt {
      padding-top: 20px;
      font-size: 13px;
      line-height: 1.7;
      font-weight: 500;
    }
  }
}
