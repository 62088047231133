.FifthPage{
    background-image: url('../../../_asset/images/mainback/bg-05.png');
    background-repeat: no-repeat;
    -webkit-background-size: cover; 
    -moz-background-size: cover; 
    -o-background-size: cover; 
    background-size: cover;
    background-position: center;
    .Link{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 155px;
    }
}
//Tablet | Mobile 분기점

@media all and (max-width:1200px) {
	.FifthPage{
    background-image: url('../../../_asset/images/mainback/tabletbg-05.png');
	
	}
  }

@media all and (max-width:1024px) {
	.FifthPage{
    
	  .Link{
		padding-bottom: 130px;
	  }
	}
  }

  
@media all and (max-width:767px) {
    .FifthPage{
      background-image: url('../../../_asset/images/mainback/mobg-05.png');
        .Link{
        padding-bottom: 120px;
        }
    }
}
