.mediacontainer {
  overflow: hidden;
  position: relative;
  height: 3295px;
  background-size: cover;
  .media_top {
    position: relative;
    z-index: 10;
    width: 1226px;
    margin: 162px auto 190px;
    .phone_image {
      margin: 133px 0 385px;
      text-align: center;
      > img {
        width: 335px;
        height: 707px;
        border-radius: 37px;
        box-shadow: 33px 30px 79px rgba(0, 0, 0, 0.4);
      }
    }
    .leftbox {
      display: flex;
      overflow: visible;
      position: relative;
      width: 50%;
      vertical-align: top;

      .left_text {
        position: relative;
        top: 36%;
        padding-right: 20px;
        font-weight: 900;
        max-height: 80px;
        height: 100%;
        border-bottom: solid 1px #161616;
      }
      .left_img {
        > img {
          margin-right: 75px;
          width: 335px;
          height: 707px;
          border-radius: 37px;
          box-shadow: 33px 30px 79px rgba(0, 0, 0, 0.4);
        }
      }
    }
    .rightbox {
      margin-top: 345px;
      display: flex;
      overflow: visible;
      position: relative;
      width: 50%;
      vertical-align: top;
      .video {
        width: auto;
        height: 707px;
        margin-left: 75px;
        border-radius: 30px;
        border: 10px solid #000000;
        box-shadow: 33px 30px 79px rgba(0, 0, 0, 0.4);
      }
      .right_text {
        display: flex;
        text-align: right;
        position: relative;
        top: 36%;
        padding-left: 20px;
        font-weight: 900;
        max-height: 80px;
        height: 100%;
        border-bottom: solid 1px #161616;
      }
    }
  }
}
