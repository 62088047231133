.ThirdPage{
    display: flex;
    justify-content: center;
    background-image: url('../../../_asset/images/mainback/bg-03.png');
    background-repeat: no-repeat;
    -webkit-background-size: cover; 
    -moz-background-size: cover; 
    -o-background-size: cover; 
    background-size: contain;
    background-color: #222222;
    font-family: Montserrat;
    .ThirdText{
        display: flex;
        font-size: 250px;
        font-weight: 900;
        align-items: center;
        color: #ffffff;
    }
    .Link{
        position: absolute;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 155px;
        z-index: 10;
    }
}

//Tablet | Mobile 분기점
@media all and (max-width:1024px) {
	.ThirdPage{
	  .Link{
		padding-bottom: 130px;
	  }
	}
}
  
@media all and (max-width:767px) {
	.ThirdPage{
	  .Link{
		padding-bottom: 120px;
	  }
	}
}