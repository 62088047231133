.CloseBtnContainer{
    display: flex;
    font-family: Montserrat;
}
.CloseBtnBox{
        overflow: visible;
        border: none;
        background-color: transparent;
        cursor: pointer;
        appearance: none;
        text-decoration: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        
}
.CloseBtnText{
    line-height: 1;
    color:#e7304d;
    margin-right: 4px;
    font-size: 15px;
    font-family: Montserrat;
    font-weight: 500;
}
button {
    overflow: visible;
    border: none;
    background-color: transparent;
    cursor: pointer;
    appearance: none;
    text-decoration: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn{
    margin-left: 13px;
}
//Tablet | Mobile 분기점
@media all and (max-width:1024px) {
    .CloseBtnText{
        font-size: 16px;
    }
    .CloseBtnBox{
        padding-right: initial;
    }
}

@media all and (max-width:400px) {
    .CloseBtnText{
        font-size: 14px;
    }
  }
  
  @media all and (max-width:300px) {
    .CloseBtnText{
        font-size: 12px;
    }
  }