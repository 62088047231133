.connectcontainer {
  .connect_top {
    width: 1226px;
    margin: 0 auto;

    .connect_image01 {
      display: inline-block;
      margin-right: 4.2vw;
      vertical-align: top;
      > img {
        margin-bottom: 90px;
        width: 319px;
        height: 692px;
        display: block;
        box-shadow: 40px 40px 70px rgba(34, 34, 34, 0.08);
      }
    }
    .connect_image02 {
      margin-top: 345px;
      display: flex;
      flex-direction: column;
      margin-right: 4.2vw;
      vertical-align: top;
      > img {
        margin-bottom: 80px;
        width: 319px;
        height: 692px;
        box-shadow: 40px 40px 70px rgba(34, 34, 34, 0.08);
      }
    }
    .connect_image03 {
      margin-top: 690px;
      display: inline-block;
      margin-right: 4.2vw;
      vertical-align: top;
      > img {
        width: 319px;
        height: 692px;
        box-shadow: 40px 40px 70px rgba(34, 34, 34, 0.08);
      }
    }
  }
}
