.socialcontainer {
  overflow: hidden;
  background: #000;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  .social_top {
    width: 1226px;
    margin: 253px auto 0;
    .list {
      display: inline-block;
      margin-right: 39px;
      vertical-align: top;
      > img {
        width: 320px;
        height: 693px;
      }
    }
  }
}
