.HeaderLogoMain{

  .HeaderLogo {
    font-size: 30px;
    font-weight: bold;
    font-family: "Montserrat";
    line-height: 1;
  }
  .Black{
    color: #000;
  }
  .White{
    color: #fff;
  }
}
@media (max-width:1270px) {
  .HeaderLogo{
    font-size: 29px !important;
  }
}

//Tablet | Mobile 분기점
@media all and (max-width:767px) {
  .HeaderLogo{
    font-size: 20px !important;
  }
}
@media all and (max-width:400px) {
  .HeaderLogo{
    font-size: 20px !important;
  }
}

@media all and (max-width:350px) {
  .HeaderLogo{
    font-size: 20px !important;
  }
}

@media all and (max-width:300px) {
  .HeaderLogo{
    font-size: 17px !important;
  }
}