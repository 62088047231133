body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "GodthamRoundedTTF", "NotoSerifKR", "GmarketSansTTF", "Montserrat",
    "NotoSansKR" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #222222;

  /* 스크롤 바 없애기 */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* 크롬 스크롤바 없애기 */
body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "MyFriend";
  font-style: normal;
  font-weight: 500;
  src: url("../src/_asset/fonts/myfriend.ttf");
}
@font-face {
  font-family: "Marion";
  font-style: normal;
  font-weight: 500;
  src: url("../src/_asset/fonts/Marion\ Regular.ttf");
}

@font-face {
  font-family: "GmarketSansTTF";
  font-style: normal;
  font-weight: 900;
  src: url("../src/_asset/fonts/GmarketSansTTFBold.ttf");
}
@font-face {
  font-family: "GmarketSansTTF";
  font-style: normal;
  font-weight: 600;
  src: url("../src/_asset/fonts/GmarketSansTTFMedium.ttf");
}
@font-face {
  font-family: "GmarketSansTTF";
  font-style: normal;
  font-weight: 300;
  src: url("../src/_asset/fonts/GmarketSansTTFLight.ttf");
}
@font-face {
  font-family: "GodthamRoundedTTF";
  font-style: normal;
  font-weight: 900;
  src: url("../src/_asset/fonts/GothamRoundedBold_21016.ttf");
}
@font-face {
  font-family: "GodthamRoundedTTF";
  font-style: normal;
  font-weight: 600;
  src: url("../src/_asset/fonts/GothamRoundedMedium_21022.ttf");
}
@font-face {
  font-family: "GodthamRoundedTTF";
  font-style: normal;
  font-weight: 300;
  src: url("../src/_asset/fonts/GothamRoundedLight_21020.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  src: url("../src/_asset/fonts/Montserrat-Thin.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  src: url("../src/_asset/fonts/Montserrat-ExtraLight.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("../src/_asset/fonts/Montserrat-Light.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../src/_asset/fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../src/_asset/fonts/Montserrat-Medium.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("../src/_asset/fonts/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../src/_asset/fonts/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  src: url("../src/_asset/fonts/Montserrat-ExtraBold.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  src: url("../src/_asset/fonts/Montserrat-Black.ttf");
}

/* g9 fonts */
@font-face {
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 100;
  src: url("../src/_asset/fonts/NotoSansKR-Thin.otf");
}
@font-face {
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 300;
  src: url("../src/_asset/fonts/NotoSansKR-Light.otf");
}
@font-face {
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 400;
  src: url("../src/_asset/fonts/NotoSansKR-Regular.otf");
}
@font-face {
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 500;
  src: url("../src/_asset/fonts/NotoSansKR-Medium.otf");
}
@font-face {
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 700;
  src: url("../src/_asset/fonts/NotoSansKR-Bold.otf");
}
@font-face {
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 900;
  src: url("../src/_asset/fonts/NotoSansKR-Black.otf");
}

/* prorenata fonts */
@font-face {
  font-family: "NotoSerifKR";
  font-style: normal;
  font-weight: 100;
  src: url("../src/_asset/fonts/NotoSerifCJKkr-ExtraLight.otf");
}
@font-face {
  font-family: "NotoSerifKR";
  font-style: normal;
  font-weight: 300;
  src: url("../src/_asset/fonts/NotoSerifCJKkr-Light.otf");
}
@font-face {
  font-family: "NotoSerifKR";
  font-style: normal;
  font-weight: 400;
  src: url("../src/_asset/fonts/NotoSerifCJKkr-Regular.otf");
}
@font-face {
  font-family: "NotoSerifKR";
  font-style: normal;
  font-weight: 500;
  src: url("../src/_asset/fonts/NotoSerifCJKkr-Medium.otf");
}
@font-face {
  font-family: "NotoSerifKR";
  font-style: normal;
  font-weight: 600;
  src: url("../src/_asset/fonts/NotoSerifCJKkr-SemiBold.otf");
}
@font-face {
  font-family: "NotoSerifKR";
  font-style: normal;
  font-weight: 700;
  src: url("../src/_asset/fonts/NotoSerifCJKkr-Bold.otf");
}
@font-face {
  font-family: "NotoSerifKR";
  font-style: normal;
  font-weight: 900;
  src: url("../src/_asset/fonts/NotoSerifCJKkr-Black.otf");
}

@font-face {
  font-family: "AveriaSerif";
  font-style: thin;
  font-weight: 300;
  src: url("../src/_asset/fonts/AveriaSerifLibre-Light.ttf");
}
@font-face {
  font-family: "AveriaSerif";
  font-style: normal;
  font-weight: 500;
  src: url("../src/_asset/fonts/AveriaSerifLibre-Regular.ttf");
}
@font-face {
  font-family: "AveriaSerif";
  font-style: bold;
  font-weight: 700;
  src: url("../src/_asset/fonts/AveriaSerifLibre-Bold.ttf");
}