.HiringContainer{
    color: #fff;
    .HiringMain{
    display: flex;
    flex-direction: column;
    max-width: 1630px;
    width: 100%;
    margin: auto;
    padding: 0 30px;
    }
    .HiringTitle{
        font-family: Montserrat;
        font-size: 25px;
        font-weight: 800;
        margin-bottom: 35px;
        margin-top: 75px;
    }
    .HiringBox{
        display: flex;
        flex-direction: column;
    }
    .HiringSubLineLeft{
        display: flex;
        flex: 2;
    }
    .HiringSubLineRight{
        font-family: NotoSansKr;
        display: flex;
        flex: 1;
    }
    .HiringTitleLineLeft{
        display: flex;
        flex: 2;
    }
    .HiringTitleLineRight{
        display: flex;
        flex: 1;
    }
    .Career, .Period, .Personnel{
        flex: 1;
        text-align: center;
    }
    .Job{
        text-align: left;
        flex: 1;
        padding-left: 70px;
    }
    .Field{
        text-align: left;
        flex: 2;
    }
    .HiringSub.Job{
        font-family: Montserrat;
    }
    .HiringSub.Field{
        font-family: NotoSansKr;
    }
    .HiringTitleLine{
        background-color: #fff;
        color: black;
        display: flex;
        font-family: NotoSansKr;
        height: 60px;
        align-items: center
    }
    .HiringSubLineBox{
        display: flex;
        height: 100px;
        border-bottom: 1px solid #fff;
        align-items: center;
        cursor: pointer;
    }
    .HiringSubLineBox:hover{
        background-color: #333;
    }
    .Hiringbutton{
        display: flex;
        background-color: #fff;
        font-family: NotoSansKr;
        max-width: 342px;
        width: 100%;
        height: 70px;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin: auto;
        margin-top: 70px;
        color: #333;
    }
    .HiringTitleText{
        font-size: 17px;
        font-weight: bold;
    }
    .HiringSub{
        font-size: 16px;
        font-weight: 300;
        span{
        font-weight: 600;
        }
    }
}

@media all and (max-width: 1023px){
    .HiringContainer{
        .HiringMain{
            padding: 0 50px;
        }
        .HiringTitle{
            font-size: 23px;
            margin-bottom: 25px;
            margin-top: 50px;
        }
        .Job{
            padding-left: 40px;
        }
        .HiringTitleText{
            font-size: 15px;
        }
        .HiringSub{
            font-size: 15px;
        }
        .HiringSubLineBox{
            height: 80px;
        }
        .Hiringbutton{
            max-width: 250px;
            height: 60px;
            font-size: 18px;
            margin-top: 50px;
        }
    }
}

@media all and (max-width: 767px){
    .HiringContainer{
        .HiringMain{
            padding: 0 25px;
        }
        .HiringTitle{
            font-size: 22px;
            margin-bottom: 20px;
            margin-top: 40px;
        }
        .Job{
            padding-left: 20px;
        }
        .Field{
            text-align: center;
        }
        .HiringTitleText{
            font-size: 14px;
        }
        .HiringSub{
            font-size: 14px;
        }
        .Hiringbutton{
            height: 60px;
            font-size: 16px;
            margin-top: 40px;
        }
    }
}
@media all and (max-width: 600px){
    .HiringContainer{
        .Personnel{
            display: none;
        }

    }
}

@media all and (max-width: 550px){
    .HiringContainer{
        .HiringTitleText{
            font-size: 13px;
        }
        .HiringSub{
            font-size: 13px;
        }
        .HiringSubLineBox{
            height: 70px;
        }
        .HiringTitleLine{
            height: 50px;
        }
    }
}
@media all and (max-width: 500px){
    .HiringContainer{
        .HiringTitleText{
            font-size: 12px;
        }
        .HiringSub{
            font-size: 12px;
        }
    }
}

@media all and (max-width: 450px){
    .HiringContainer{
        .Job{
            padding-left: 15px;
            margin-right: 10px;
        }
        .Period{
            display: none;
        }
        .HiringSubLineLeft{
            align-items: center;
        }
        .Career{

        }
        .HiringTitleLineRight, .HiringSubLineRight{
            max-width: 65px;
            align-items: flex-end;
        }
        .Hiringbutton{
            max-width: 100%;
            height: 60px;
            font-size: 15px;
            margin-top: 30px;
        }
    }
}
@media all and (max-width: 400px){
    .HiringContainer{
        .HiringTitleText{
            font-size: 11px;
        }
        .HiringSub{
            font-size: 11px;
        }
    }
}
@media all and (max-width: 350px){
    .HiringContainer{
        .HiringTitleText{
            font-size: 10x;
        }
        .HiringSub{
            font-size: 10px;
        }
    }
}