.NoticeGridMain {
    .experience__grid {
        max-width: 1630px;
        width: 100%;
        margin: 0px 50px;
        padding: 80px 30px 50px;
        overflow: hidden;

        .NoticeTitle {
            font-family: Montserrat;
            color: #fff;
            font-size: 25px;
            font-weight: 800;
            margin-bottom: 20px;
        }
        .NoticeTextBox {
            font-family: Montserrat;
            padding: 1%;
            background-color: #222222;
            border: none;
        }
        .TextTitle {
            font-size: 14px;
            font-weight: 300;
            color: #cccccc;
            margin-top: 10px;
        }
        .TextSub {
            font-size: 14px;
            font-weight: 300;
            color: #6c6c6c;
            margin-top: 15px;
            line-height: 1.43;
        }
        .TextSort {
            font-size: 14px;
            font-weight: 300;
            color: #464646;
            margin-top: 5px;
            margin-bottom: 15px;
        }
    }

    .experience__list {
        display: flex;
        flex-flow: row wrap;
        margin: 0px auto;
        position: relative;
        height: 100%;
    }

    .experience__item {
        flex-grow: 0;
        margin: 5px;
        display: none;
        width: 32%;
        height: auto;
        overflow: hidden;
        vertical-align: top;
        position: relative;
    }

    .experience__item.active {
        display: inline-block;
    }

    .experience__item.visible {
        opacity: 1;
    }

    .experience__item:hover {
        opacity: 1;
    }

    .experience__item a.item__link {
        display: inline-block;
        width: 100%;
        height: 100%;
        position: relative;
    }

    .experience__item:hover .item__frame {
        transform: scale(0.9) !important;
    }

    .experience__item a.item__link .item__frame {
        display: inline-block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        transition: transform 0.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
        z-index: 10;
    }

    .experience__item a.item__link .item__frame .item__dimd {
        // position: absolute;
        // top: -1%;
        // left: -1%;
        width: 100%;
        height: auto;
        background-color: #222222;
        z-index: 20;
        > img {
            width: 100%;
            height: 100%;
        }
    }

    .experience__item.visible .item__visual {
        visibility: visible !important;
    }

    .experience__item:hover .item__visual {
        transform: scale(1.2) !important;
    }

    .experience__item a.item__link .item__frame .item__visual {
        display: inline-block;
        width: 100%;
        height: 100%;
        transition: transform 0.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
        position: relative;
    }

    .experience__item a.item__link .item__frame .item__visual .image-box {
        width: 100%;
        height: 100%;
        display: inline-block;
        position: absolute;
        top: 0px;
        left: 0px;
        overflow: hidden;
        z-index: 10;
        transition: opacity 0.2s ease 0s;
    }

    .image-box {
        display: inline-block;
        position: relative;
        box-sizing: border-box;
    }

    .experience__item a.item__link .item__frame .item__visual .image-box img {
        width: 100%;
        height: 100%;
        position: relative;
        display: block;
        object-fit: cover;
    }

    img {
        vertical-align: top;
    }

    .experience__item:hover .badge,
    .experience__item:hover .video-box {
        opacity: 1 !important;
    }

    .experience__item a.item__link .item__frame .item__visual .video-box {
        width: 100%;
        height: 100%;
        position: absolute;
        display: inline-block;
        top: 0px;
        left: 0px;
        z-index: 15;
        transition: opacity 0.4s ease 0s;
        opacity: 0;
    }

    .experience__item a.item__link .item__frame .item__visual .video-box video {
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;
    }

    .experience__item .experience__badge {
        z-index: 11;
        width: 100%;
        height: auto;
        position: absolute;
    }

    .experience__item .experience__badge .n-badge {
        transform: translateY(10px);
        transition: transform 0.3s ease 0s, opacity 0.2s ease 0s;
        opacity: 0;
        font-size: 14px;
    }

    .experience__item:hover .n-badge {
        opacity: 1;
        transform: translateY(0px);
    }

    .n-badge.center .n-badge-wrap {
        transform: translateX(0px) !important;
    }

    .experience__item .experience__badge .n-badge .n-badge-item {
        opacity: 1;
        padding: 0.5em 1em;
    }

    .experience__item.play .video-box {
        opacity: 1 !important;
    }
    .experience__grid {
        transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: unset;
        margin: auto;
    }
    @-webkit-keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @media all and (max-width: 1400px) {
        .experience__grid {
            .TextTitle {
                font-size: 13px;
            }
            .TextSub {
                font-size: 13px;
            }
            .TextSort {
                font-size: 13px;
            }
        }
    }

    @media all and (max-width: 1150px) {
        .experience__grid {
            .TextTitle {
                font-size: 12px;
                margin-top: 7px;
            }
            .TextSub {
                font-size: 12px;
                margin-top: 8px;
            }
            .TextSort {
                font-size: 12px;
            }
        }
    }

    @media all and (max-width: 1024px) {
        .experience__item {
            width: 48%;
        }
        .experience__grid {
            padding: 50px 50px 0;
            .TextTitle {
                font-size: 13px;
                margin-top: 10px;
            }
            .TextSub {
                font-size: 13px;
                margin-top: 15px;
            }
            .TextSort {
                font-size: 13px;
            }
        }
    }

    @media all and (max-width: 850px) {
        .experience__grid {
            .TextTitle {
                font-size: 12px;
                margin-top: 7px;
            }
            .TextSub {
                font-size: 12px;
                margin-top: 8px;
            }
            .TextSort {
                font-size: 12px;
            }
        }
    }

    @media all and (max-width: 767px) {
        .experience__item {
            width: 48%;
        }
        .experience__grid {
            padding: 30px 25px 0;
            .NoticeTitle {
                font-size: 23px;
            }
            .TextTitle {
                font-size: 13px;
                margin-top: 10px;
            }
            .TextSub {
                font-size: 13px;
                margin-top: 15px;
            }
            .TextSort {
                font-size: 13px;
            }
        }
    }

    @media all and (max-width: 550px) {
        .experience__item {
            width: 98%;
        }
        .experience__grid {
            .TextTitle {
                font-size: 14px;
            }
            .TextSub {
                font-size: 14px;
            }
            .TextSort {
                font-size: 14px;
            }
        }
    }

    @media all and (max-width: 350px) {
        .experience__grid {
            .TextTitle {
                font-size: 13px;
            }
            .TextSub {
                font-size: 13px;
            }
            .TextSort {
                font-size: 13px;
            }
        }
    }
}
