.con_premium_container {
  .con_premium {
    position: relative;
    width: 1200px;
    margin: 202px auto 0;
    padding-top: 648px;
    .brand_film {
      overflow: hidden;
      position: relative;
      height: 100vh;
    }
  }
}
