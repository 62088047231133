
.experience__category {
    position: fixed;
    width: auto;
    height: auto;
    z-index: 50;
    top: 50%;
    left: 50px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    transform: translateY(-50%) translateZ(0px);
    transition: margin 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s, opacity 0.3s ease 0s;
  }
  
  .experience__category__name {
    cursor: pointer;
    margin: 0.1em 0px;
    line-height: 0.77em;
    font-size: 200px;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: calc(0.005em + 1px) rgb(0, 0, 0);
  }
  
  .experience__category__name.selected {
    color: rgb(0, 0, 0) !important;
  }
  .experience__category__name .mask-text>span {
    transition: transform .4s cubic-bezier(.215,.61,.355,1);
    display: inline-block;
  }
  .experience__category__name .mask-text:hover>span {
    transition: transform 0.3s ease 0s, color 0.2s ease 0s;
    transform: scale(0.93);
    color: rgb(0,0,0);

  }
  @media (max-width: 767px) {
    .experience__category {
      display: none;
    }
  }
  
  @media (max-height: 768px) and (max-width: 1024px) {
    .experience__category__name {
      font-size: 160px;
    }
  }
  
  @media (max-height: 768px) and (max-width: 1024px) {
    .experience__category__name {
      font-size: 160px;
    }
  }
  
  @media (max-height: 768px) and (max-width: 1024px) {
    .experience__category__name {
      font-size: 160px;
    }
  }