.Pidentify_container {
  overflow: hidden;
  height: 250px;
  background: #fff;
  .Pidentify_cont {
    overflow: hidden;
    position: relative;
    width: 960px;
    height: 100%;
    margin: 0 auto;
    .Pidentify_txt_bx {
      overflow: hidden;
      margin-top: 80px;
      .Pidentify_tit {
        font-size: 13px;
        font-weight: 500;
      }
      .Pidentify_txt {
        padding-top: 20px;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.7;
      }
    }
  }
}
