.strategycontainer {
  overflow: hidden;
  position: relative;
  z-index: 10;
  width: 1226px;
  height: auto;
  margin: 0 auto;
  .strategy_top {
  }
  .List_map {
    color: #fff;

    .list_top {
      display: block;
      margin-bottom: 45px;
      padding-bottom: 115px;
      border-bottom: 6px solid #fff;
      .number {
        font-size: 40px;
        font-weight: 600;
      }
      .title {
        margin-top: 50px;
        font-size: 40px;
        font-weight: 900;
        letter-spacing: 1.2px;
      }
    }
    .list_bottom {
      display: flex;
      justify-content: space-between;
      .em {
        font-size: 40px;
        font-weight: 900;
        flex: 1;
      }
      .text {
        flex: 1.2;
        font-size: 14px;
        line-height: 2;
      }
    }
  }
}
