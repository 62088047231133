.ex-main-grid {
    .experience__grid {
        // max-width: 1630px;
        width: 100%;
        padding-bottom: 50px;
        overflow: hidden;
        transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: unset;
    }

    .experience__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        margin: 0px auto;
        position: relative;
        height: 100%;
        .no-data {
            grid-template-columns: 1fr;
        }
    }

    .experience__item {
        width: 100%;
        height: auto;
        overflow: hidden;
        vertical-align: top;
        position: relative;
    }

    .no-data {
        margin-top: 50px;
        width: 100%;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-family: Monsterat, sans-serif;
        gap: 15px;
        text-align: center;
        img {
            width: 80px;
        }
        .top {
            font-size: 20px;
        }
        .sub {
            color: #999;
        }
    }

    .experience__item.active {
        display: inline-block;
    }

    .experience__item.visible {
        opacity: 1;
    }

    .experience__item:hover {
        opacity: 1;
    }

    .experience__item a.item__link {
        display: flex;
        width: 100%;
        // height: 100%;
        position: relative;
    }

    .experience__item:hover .item__frame {
        transform: scale(0.9) !important;
    }

    .experience__item a.item__link .item__frame {
        display: inline-block;
        width: 100%;
        // height: 100%;
        overflow: hidden;
        transition: transform 0.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
        z-index: 10;

        // Fade in Animations
        -webkit-animation: fade-in 2.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        animation: fade-in 2.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }

    .experience__item a.item__link .item__frame .item__dimd {
        width: 100%;
        // height: 100%;
        background-color: rgb(221, 221, 221);
        z-index: 20;
        animation: 60ms cubic-bezier(0.215, 0.61, 0.355, 1) 50ms 1 normal both running slideOutDown;
        > img {
            width: 100%;
            height: 100%;
        }
    }

    .experience__item.visible .item__visual {
        visibility: visible !important;
    }

    .experience__item:hover .item__visual {
        transform: scale(1.2) !important;
    }

    .experience__item a.item__link .item__frame .item__visual {
        display: inline-block;
        width: 100%;
        // height: 100%;
        transition: transform 0.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
        position: relative;
    }

    .experience__item a.item__link .item__frame .item__visual .image-box {
        width: 100%;
        // height: 100%;
        display: inline-block;
        position: absolute;
        top: 0px;
        left: 0px;
        overflow: hidden;
        z-index: 10;
        transition: opacity 0.2s ease 0s;
    }

    .image-box {
        display: inline-block;
        position: relative;
        box-sizing: border-box;
    }

    .experience__item a.item__link .item__frame .item__visual .image-box img {
        width: 100%;
        // height: 100%;
        position: relative;
        display: block;
        object-fit: cover;
    }

    img {
        vertical-align: top;
    }

    .experience__item:hover .badge,
    .experience__item:hover .video-box {
        opacity: 1 !important;
    }

    .experience__item a.item__link .item__frame .item__visual .video-box {
        width: 100%;
        // height: 100%;
        position: absolute;
        display: inline-block;
        top: 0px;
        left: 0px;
        z-index: 15;
        transition: opacity 0.4s ease 0s;
        opacity: 0;
    }

    .experience__item a.item__link .item__frame .item__visual .video-box video {
        object-fit: cover;
        width: 100%;
        // height: 100%;
        display: block;
    }

    .experience__item .experience__badge {
        z-index: 11;
        width: 100%;
        // height: auto;
        position: absolute;
    }

    .experience__item .experience__badge .n-badge {
        transform: translateY(10px);
        transition: transform 0.3s ease 0s, opacity 0.2s ease 0s;
        opacity: 0;
        font-size: 14px;
    }

    .experience__item:hover .n-badge {
        opacity: 1;
        transform: translateY(0px);
    }

    .n-badge.center .n-badge-wrap {
        transform: translateX(0px) !important;
    }

    .experience__item .experience__badge .n-badge .n-badge-item {
        opacity: 1;
        padding: 0.5em 1em;
    }

    .experience__item.play .video-box {
        opacity: 1 !important;
    }

    @-webkit-keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @media all and (max-width: 1024px) {
        .experience__list {
            grid-template-columns: 1fr 1fr;
        }
    }

    @media all and (max-width: 560px) {
        .experience__list {
            grid-template-columns: 1fr;
        }
    }
}
