.brandcontainer {
  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-top: -40px;
  .cnt_top {
    position: relative;
    z-index: 10;
    width: 1226px;
    margin: 254px auto 0;
  }
  .brand_img_01 {
    > img {
      width: 335px;
      height: 707px;
      border-radius: 37px;
      box-shadow: 33px 30px 79px rgba(0, 0, 0, 0.4);
    }
  }
  .brand_img_02 {
    margin: 272px 0 0 112px;
    display: inline-block;
    vertical-align: top;

    > img {
      width: 335px;
      height: 707px;
      border-radius: 37px;
      box-shadow: 33px 30px 79px rgba(0, 0, 0, 0.4);
    }
  }
  .cnt {
    width: 1226px;
    margin: 313px auto 0;
    padding-bottom: 253px;
    .list_img {
      position: absolute;
      top: -786px;
      left: 0;
      > img {
        width: 305px;
        height: 1625px;
        box-shadow: 33px 30px 79px rgba(0, 0, 0, 0.4);
      }
    }
    .list_img02 {
      position: absolute;
      top: 125px;
      left: 360px;
      > img {
        width: 312px;
        height: 976px;
        box-shadow: 33px 30px 79px rgba(0, 0, 0, 0.4);
      }
    }
    .list_img03 {
      position: absolute;
      top: 645px;
      left: 715px;
      > img {
        width: 312px;
        height: 575px;
        box-shadow: 33px 30px 79px rgba(0, 0, 0, 0.4);
      }
    }
  }
}
